import { getAnnouncements, getAnnouncement } from '@/admin/announcements';
import { reactive } from '@vue/composition-api';
import { DisplayDate } from '@/admin/util';

interface AnnouncementProperty {
  announcementId: string;
  title: string;
  releaseDate: string;
  isNew: boolean;
}

class Announcements {
  items: AnnouncementProperty[] = [];
  nextToken: string | undefined;
  isFetchLoading = true;

  constructor() {
    getAnnouncements()
      .then((result) => {
        this.items = result.announcements.map((announcement) => ({
          announcementId: announcement.announcementId,
          title: announcement.title,
          releaseDate: new DisplayDate(announcement.releaseDate).format(),
          isNew: new Date().getTime() - announcement.releaseDate < 7 * 24 * 60 * 60 * 1000,
        }));
        this.nextToken = result.nextToken;
      })
      .finally(() => (this.isFetchLoading = false));
  }
}

export const useAnnouncements = () => {
  const announcements = new Announcements();
  return { announcements: reactive(announcements) };
};

class Announcement {
  announcementId: string;
  title = '';
  body = '';
  releaseDate = '';
  isFetchLoading = true;

  constructor(announcementId: string) {
    this.announcementId = announcementId;
    getAnnouncement(this.announcementId)
      .then((announcement) => {
        this.title = announcement.title;
        this.body = announcement.body.replace(/\r\n|\n|\r/gm, '<br>');
        this.releaseDate = new DisplayDate(announcement.releaseDate).format();
      })
      .finally(() => (this.isFetchLoading = false));
  }
}

export const useAnnouncement = (announcementId: string) => {
  const announcement = new Announcement(announcementId);
  return { announcement: reactive(announcement) };
};
