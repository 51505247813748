






























import { defineComponent } from '@vue/composition-api';
import { useAnnouncements } from '@/composition/announcements';

export default defineComponent({
  name: 'Announcements',
  setup() {
    const { announcements } = useAnnouncements();
    return {
      pageTitle: 'お知らせ',
      announcements,
    };
  },
});
