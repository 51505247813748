import core from '@/admin/core';

interface AnnouncementResponse {
  announcements: Announcement[];
  nextToken?: string;
}

interface Announcement {
  announcementId: string;
  title: string;
  body: string;
  releaseDate: number;
}

export const getAnnouncements = async () => {
  const result = await core.httpClient.get('/admin/public/announcements');
  return result.data as AnnouncementResponse;
};

export const getAnnouncement = async (announcementId: string) => {
  const result = await core.httpClient.get(`/admin/public/announcements/${announcementId}`);
  return result.data as Announcement;
};
